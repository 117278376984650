import { Box, Divider, Stack, Typography } from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { useMemo } from "react";
import { FleetScenarioChargerLevel, FleetScenarioVehicle } from "types/fleets";
import { generate24HourLabels } from "utils/time";

export type FleetOverviewProps = {
  scope?: string[];
  vehiclePurchaseSuggestions?: FleetScenarioVehicle[];
  chargerPurchaseSuggestions?: FleetScenarioChargerLevel[];
};

function FleetOverview({
  scope,
  vehiclePurchaseSuggestions,
  chargerPurchaseSuggestions,
}: FleetOverviewProps) {
  const timeLabels = generate24HourLabels();

  const evVehicleCount = useMemo(() => {
    return vehiclePurchaseSuggestions?.reduce((total, vehicle) => {
      return total + (vehicle.num_electric_vehicles || 0);
    }, 0);
  }, [vehiclePurchaseSuggestions]);

  const vehicles = useMemo(() => {
    return vehiclePurchaseSuggestions?.map((vehicle) => ({
      ...vehicle,
      reference_vehicle:
        vehicle.num_electric_vehicles === 0 ? "N/A" : vehicle.reference_vehicle,
      iceReferenceVehicle:
        vehicle.num_ice_vehicles === 0 ? "N/A" : vehicle.iceReferenceVehicle,
      otherEvReferenceVehicle:
        vehicle.num_electric_vehicles === 0
          ? "N/A"
          : vehicle.otherEvReferenceVehicle.join(", "),
    }));
  }, [vehiclePurchaseSuggestions]);

  const vehicleColumns: GridColumns = [
    {
      field: "reference_vehicle",
      headerName: "EV Reference Make/Model",
      minWidth: 180,
      flex: 1,
    },
    {
      field: "otherEvReferenceVehicle",
      headerName: "Other EV Reference Make/Model",
      minWidth: 180,
      flex: 1,
    },
    {
      field: "num_electric_vehicles",
      headerName: "Quantity (EV)",
      maxWidth: 120,
      flex: 1,
    },
    {
      field: "iceReferenceVehicle",
      headerName: "ICE Reference Make/Model",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "num_ice_vehicles",
      headerName: "Quantity (ICE)",
      maxWidth: 120,
      flex: 1,
    },
    {
      field: "operatingHours",
      headerName: "Operating Hours",
      maxWidth: 120,
      flex: 1,
      valueFormatter: (params) => {
        return `${timeLabels[params.value[0]]} - ${
          timeLabels[params.value[1] === 24 ? 0 : params.value[1]]
        }`;
      },
    },
    {
      field: "vehicle_costs",
      headerName: "Total Capex (USD)",
      valueFormatter: (params) =>
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(Number(params.value)),
      maxWidth: 140,
      flex: 1,
    },
  ];

  const chargerColumns: GridColumns = [
    {
      field: "reference_charger",
      headerName: "Reference Make/Model",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "capacity",
      headerName: "Level (KW)",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "num_of_chargers",
      headerName: "Quantity",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "charger_cost",
      headerName: "Total Capex (USD)",
      valueFormatter: (params) =>
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(Number(params.value)),
      minWidth: 140,
      flex: 1,
    },
  ];

  const filteredVehicleRows = scope?.includes("Vehicles") ? vehicles : [];

  const filteredChargerRows =
    scope?.includes("Chargers/EVSE") && evVehicleCount && evVehicleCount > 0
      ? chargerPurchaseSuggestions
      : [];

  return (
    <Box>
      <Typography variant="controlTitle" sx={{ fontWeight: "bold" }}>
        Fleet Overview
      </Typography>
      <Stack spacing={2} divider={<Divider />}>
        <Stack spacing={1}>
          <Typography
            variant="h3"
            color={"GrayText"}
            sx={{ marginTop: "15px", fontWeight: "bold" }}
          >
            Vehicles
          </Typography>
          <Box sx={{ height: "300px" }}>
            <DataGrid
              sx={{
                "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                  py: "8px",
                },
                "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                  py: "15px",
                },
                "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                  py: "22px",
                },
              }}
              getRowHeight={() => "auto"}
              rows={filteredVehicleRows ?? []}
              columns={vehicleColumns}
              checkboxSelection={false}
              loading={false}
              keepNonExistentRowsSelected={false}
            />
          </Box>
        </Stack>
        <Stack spacing={1}>
          <Typography
            variant="h3"
            color={"GrayText"}
            sx={{ fontWeight: "bold" }}
          >
            Chargers
          </Typography>
          <Box sx={{ height: "300px" }}>
            <DataGrid
              rows={filteredChargerRows ?? []}
              columns={chargerColumns}
              checkboxSelection={false}
              loading={false}
              keepNonExistentRowsSelected={false}
            />
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
}

export default FleetOverview;
