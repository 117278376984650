import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { FleetMixVehicle, VehicleType } from "../fleet-mix";
import { FleetDefaultVehicle } from "types/fleets";

export type FleetMixVehicleTypeConfigurationProps = {
  vehicle: FleetMixVehicle;
  vehicleTypeOptions: VehicleType[];
  fleetDefaultVehicles: FleetDefaultVehicle[];

  onRemove: () => void;
  onChange?: (vehicle: FleetMixVehicle) => void;
};

export function FleetMixVehicleTypeConfiguration({
  vehicle,
  vehicleTypeOptions,
  onRemove,
  onChange,
  fleetDefaultVehicles,
}: FleetMixVehicleTypeConfigurationProps) {
  const getVehicleByType = (
    vehicleType: string
  ): FleetDefaultVehicle | undefined => {
    return fleetDefaultVehicles.find(
      (vehicle) => vehicle.vehicleType === vehicleType
    );
  };

  return (
    <>
      <Stack spacing={2} direction="row" alignItems="center">
        <IconButton onClick={onRemove}>
          <RemoveCircleOutlineIcon color="error" />
        </IconButton>
        <FormControl sx={{ width: "25%" }}>
          <InputLabel id="light-duty-label" size="small">
            Vehicle Type
          </InputLabel>
          <Select
            defaultValue=""
            value={vehicle.type}
            labelId="light-duty-label"
            label="Vehicle Type"
            size="small"
            error={vehicle.typeError}
            onChange={(e) => {
              if (onChange !== undefined) {
                vehicle.typeError = false;
                vehicle.errorMessage = "";
                vehicle.type = e.target.value as VehicleType;
                const vehicleType = getVehicleByType(vehicle.type);
                vehicle.unitCostPerEV = vehicleType?.unitCostPerEV;
                vehicle.unitCostPerICE = vehicleType?.unitCostPerICE;
                onChange(vehicle);
              }
            }}
          >
            {vehicleTypeOptions.map((vehicleType) => (
              <MenuItem value={vehicleType}>{vehicleType}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label={"Number Electric"}
          type="number"
          inputProps={{ min: 0 }}
          error={vehicle.numEvError}
          value={vehicle.numEv}
          sx={{ width: "22%" }}
          size="small"
          onChange={(e) => {
            const numberOfEV = Number(e.target.value.replaceAll(".", ""));
            if (onChange !== undefined && numberOfEV >= 0) {
              vehicle.numEvError = false;
              vehicle.numIceError = false;
              vehicle.errorMessage = "";
              vehicle.numEv = Number(numberOfEV);
              onChange(vehicle);
            }
          }}
        />
        <TextField
          label={"Number ICE"}
          type="number"
          inputProps={{ min: 0 }}
          error={vehicle.numIceError}
          value={vehicle.numIce}
          sx={{ width: "22%" }}
          size="small"
          onChange={(e) => {
            const numberOfICE = Number(e.target.value.replaceAll(".", ""));
            if (onChange !== undefined && numberOfICE >= 0) {
              vehicle.numIceError = false;
              vehicle.numIceError = false;
              vehicle.errorMessage = "";
              vehicle.numIce = Number(numberOfICE);
              onChange(vehicle);
            }
          }}
        />
        <TextField
          type="number"
          inputProps={{ min: 0 }}
          label={"Total Annual Mileage"}
          value={vehicle.annualMileage}
          error={vehicle.annualMileageError}
          sx={{ width: "25%" }}
          size="small"
          onChange={(e) => {
            const mileage = Number(e.target.value);
            if (onChange !== undefined && mileage > 0) {
              vehicle.annualMileageError = false;
              vehicle.errorMessage = "";
              vehicle.annualMileage = mileage;
              onChange(vehicle);
            }
          }}
        />
        <TextField
          type="number"
          inputProps={{ min: 0, max: 10000000 }}
          label={"EV Vehicle Price"}
          value={vehicle.unitCostPerEV}
          sx={{ width: "25%" }}
          size="small"
          onChange={(e) => {
            if (onChange !== undefined) {
              const price = Number(e.target.value);
              if (price > 10000000 || price < 0) {
                return;
              }
              vehicle.errorMessage = "";
              vehicle.unitCostPerEV = price;
              onChange(vehicle);
            }
          }}
        />
        <TextField
          type="number"
          inputProps={{ min: 0, max: 10000000 }}
          label={"ICE Vehicle Price"}
          value={vehicle.unitCostPerICE}
          sx={{ width: "25%" }}
          size="small"
          onChange={(e) => {
            if (onChange !== undefined) {
              const price = Number(e.target.value);
              if (price > 10000000 || price < 0) {
                return;
              }
              vehicle.errorMessage = "";
              vehicle.unitCostPerICE = price;
              onChange(vehicle);
            }
          }}
        />
      </Stack>
      {vehicle.errorMessage && (
        <Stack>
          <Typography
            sx={{ marginLeft: 1, marginTop: -1 }}
            variant="caption"
            style={{ color: "#d32f2f" }}
          >
            {vehicle.errorMessage}
          </Typography>
        </Stack>
      )}
    </>
  );
}
