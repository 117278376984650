import { Stack, Grid, Typography, Box } from "@mui/material";

import FleetOverview from "./components/FleetOverview";
import TotalCostOfOwnership from "./components/TotalCostOfOwnership";
import { Section } from "../../components/Section";
import { PowerProfileChart } from "./components/charts/PowerProfileChart";

import AssumptionsPropertyTable from "./components/AssumptionsPropertyTable";
import { riskAssessment } from "./fleet-mix";
import { FleetScenario } from "types/fleets";
import { RiskAssessment } from "./components/wizard/RiskAssessmentDialog";
import { OrganizationPreference } from "types/organization";
import ConfigurationParameters from "./components/ConfigurationParameters";

interface FleetDownloadPageProps {
  evVehicleCount: number;
  selectedScenario: FleetScenario;
  organizationPreference: OrganizationPreference | undefined;
}

function FleetDownloadPage({
  selectedScenario,
  organizationPreference,
  evVehicleCount,
}: FleetDownloadPageProps) {
  return (
    <Box
      sx={{
        height: "100%",
        overflowY: "scroll",
        marginY: 2,
        marginX: 3,
      }}
    >
      <Typography marginTop={1} variant="h2">
        {`Scenario: ${selectedScenario.name}`}
      </Typography>
      <Grid marginTop={4} width={"80%"}>
        {organizationPreference &&
          organizationPreference.fleetRiskEnabled &&
          selectedScenario &&
          selectedScenario.risk_factor && (
            <Section>
              <Stack spacing={1.5}>
                <Stack spacing={2} direction="row">
                  <Typography variant="h6">
                    Risk assessment:{" "}
                    {evVehicleCount === 0
                      ? "N/A"
                      : selectedScenario.risk_factor}
                  </Typography>
                  <Typography variant="h6">
                    {`Risk Score: ${
                      evVehicleCount === 0
                        ? "N/A"
                        : `${selectedScenario.riskScore}/40`
                    }`}
                  </Typography>
                </Stack>

                <Typography>
                  {evVehicleCount === 0
                    ? riskAssessment.NO_EV.assessment
                    : riskAssessment[selectedScenario.risk_factor]?.assessment}
                </Typography>

                <Typography variant="h6" display="inline">
                  We recommend:{" "}
                  <Typography variant="body1" display="inline">
                    {evVehicleCount === 0
                      ? riskAssessment.NO_EV.recommend
                      : riskAssessment[selectedScenario.risk_factor]?.recommend}
                  </Typography>
                </Typography>
              </Stack>
            </Section>
          )}
      </Grid>
      <Grid container spacing={2}>
        <Grid item width={"50%"}>
          <Section>
            <FleetOverview
              scope={selectedScenario?.scope}
              vehiclePurchaseSuggestions={selectedScenario?.fleetVehicles}
              chargerPurchaseSuggestions={selectedScenario?.fleetChargerLevels}
            />
          </Section>
        </Grid>
        <Grid item container spacing={1} width={"50%"}>
          <Grid item width={"80%"}>
            <Section>
              <TotalCostOfOwnership
                scenario={selectedScenario}
                referenceScenarios={undefined}
              />
            </Section>
          </Grid>
          <Grid item width={"80%"}>
            <Section>
              <PowerProfileChart scenario={selectedScenario} />
            </Section>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item width={"50%"}>
          <Section>
            <ConfigurationParameters
              scenario={selectedScenario}
              isDownloading={true}
            />
          </Section>
        </Grid>
        <Grid item width={"40%"}>
          <Section>
            <AssumptionsPropertyTable isDownloading={true} />
          </Section>
        </Grid>
      </Grid>
      {organizationPreference && organizationPreference.fleetRiskEnabled && (
        <Grid width={"90%"}>
          <Section>
            <Typography variant="h3">Risk Assessment Score</Typography>
            <RiskAssessment />
          </Section>
        </Grid>
      )}
    </Box>
  );
}

export default FleetDownloadPage;
