import AddBoxIcon from "@mui/icons-material/AddBox";
import DownloadIcon from "@mui/icons-material/Download";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import EvStationIcon from "@mui/icons-material/EvStation";
import LayersIcon from "@mui/icons-material/Layers";
import InfoIcon from "@mui/icons-material/Info";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  InputLabel,
  Link,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import AccessControl from "../AccessControl";
import { DataGrid } from "@mui/x-data-grid";
import { useTour } from "@reactour/tour";
import Color from "colorjs.io";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import HelpTooltip from "components/HelpTooltip";
import InputSlider from "components/InputSlider";

import { loadChargingStationNetworks } from "api/charging_station";
import {
  loadChargingCapacity,
  loadDemand,
  getDemandTypeRatios,
} from "api/demand";
import { loadJustice40 } from "api/demographics";
import { loadGrowthScenario } from "api/growth_scenario";
import { loadOverlays } from "api/overlays";
import { loadSiteCollections, loadSites } from "api/sites";
import {
  BlockGroupPopupContent,
  SitePopupContent,
  ChargingStationPopupContent,
  GenericGeoJsonFeaturePopupContent,
  DemographicsPopupContent,
  Justice40PopupContent,
  SchoolDistrictPopupContent,
} from "dashboard/PopupContent";
import { useDebouncedEffect } from "dashboard/useDebouncedEffect";
import { getLightDutyVehicleClass } from "types/growth-scenario";
import {
  calculateCoveragePercentage,
  scaleChargingCapacityByUtilization,
} from "utils/coverage";
import { scaleDemandByGrowthScenarioYear } from "utils/demand";
import { createSafeFilename, downloadCsv } from "utils/file";
import { range } from "utils/array";
import UploadSiteCollectionModal from "./UploadSiteCollectionModal";
import { Layer, Map, NavigationControl, Popup, Source } from "react-map-gl";
import HomeButton from "dashboard/depots/HomeButton";
import WidgetContainer from "dashboard/depots/WidgetContainer";
import Widget from "dashboard/depots/Widget";
import Legend from "dashboard/depots/Legend";
import GeocoderControl from "dashboard/depots/geocoder-control";
import YearControl from "../controls/YearControl";
import {
  getStateAbbrFromStateName,
  getStateNameFromStateAbbr,
} from "utils/state-abbr";
import DemographicsLegend from "dashboard/coverage/DemographicsLegend";
import ChargerCoverageControlsDialog from "./ChargerCoverageControlsDialog";
import LayerControlsDialog from "./LayerControlsDialog";
import GrowthScenarioControl from "../controls/GrowthScenarioControl";
import { useAccessToken } from "utils/get-access-token";

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const ACS_METRIC_POVERTY = 2;
const ACS_METRIC_NONWHITE = 3;
const ACS_METRIC_MULTIFAMILY_HOUSING = 4;

const EXCLUSIVE_EV_NETWORKS = [
  "Tesla",
  "Tesla Destination",
  "RIVIAN_ADVENTURE",
];

function totalScore(site, siteRankWeights) {
  const { chargerCoverageRank, justiceFlagRank, scoreRank } = site.properties;
  const weightedCcRank = chargerCoverageRank * siteRankWeights.chargerCoverage;
  const weightedJusticeRank = justiceFlagRank * siteRankWeights.justice40;
  const weightedScoreRank = scoreRank * siteRankWeights.score;
  return weightedCcRank + weightedJusticeRank + weightedScoreRank;
}

function download(filename, scoredSites, siteRankWeights) {
  const formattedData = [];

  scoredSites.features.forEach((site) => {
    const formatted = {};
    formatted["name"] = site.properties.name;
    formatted[`user score (weight ${siteRankWeights.score})`] =
      site.properties.score;
    formatted[
      `site is in Justice40 region? (weight ${siteRankWeights.justice40})`
    ] = site.properties.justiceFlag;
    formatted[
      `charger coverage percentage (weight ${siteRankWeights.chargerCoverage})`
    ] = site.properties.chargerCoverage * 100;
    formatted["overall ranking"] = site.properties.rank;
    formattedData.push(formatted);
  });

  downloadCsv(formattedData, filename);
}

function SitesPage(props) {
  const { getToken } = useAccessToken();
  const utilization = 0.1;

  const [loadingChargingCapacity, setLoadingChargingCapacity] = useState(false);
  const [chargingCapacity, setChargingCapacity] = useState({});
  const [loadingSites, setLoadingSites] = useState(false);
  const [demand, setDemand] = useState({});
  const [loadingDemand, setLoadingDemand] = useState(false);
  const [allDayDemand, setAllDayDemand] = useState({});
  const [loadingAllDayDemand, setLoadingAllDayDemand] = useState(false);
  const [demandTypeRatios, setDemandTypeRatios] = useState({});
  const [loadingDemandTypeRatios, setLoadingDemandTypeRatios] = useState(false);
  const [siteCollections, setSiteCollections] = useState([]);
  const [selectedSiteCollection, setSelectedSiteCollection] = useState(null);
  const [sitesFeatureCollection, setSitesFeatureCollection] = useState({
    type: "FeatureCollection",
    features: [],
  });
  const [selectedSites, setSelectedSites] = useState([]);
  const [refreshData, setRefreshData] = useState(true);
  const [addSiteCollectionModalOpen, setAddSiteCollectionModalOpen] =
    useState(false);
  const [siteRankWeights, setSiteRankWeights] = useState({
    score: 5,
    justice40: 5,
    chargerCoverage: 5,
  });
  const [chargerCoverageControlsOpen, setChargerCoverageControlsOpen] =
    useState(false);
  const [layerControlsOpen, setLayerControlsOpen] = useState(false);

  const [overlays, setOverlays] = useState({});

  // coverage selectors
  const [includePrivateChargers, setIncludePrivateChargers] = useState(false);
  const [includeExclusiveNetworks, setIncludeExclusiveNetworks] =
    useState(false);
  const [selectedChargerLevels, setSelectedChargerLevels] = useState([
    "l1",
    "l2",
    "dcf",
  ]);
  const [evNetworks, setEvNetworks] = useState([]);

  useEffect(() => {
    const loadNetworks = async () => {
      const token = await getToken();
      const evNetworks = await loadChargingStationNetworks(token);
      setEvNetworks(evNetworks);
    };
    loadNetworks();
  }, [props.location, props.selectedChargingDemandSimulation]);

  const [loading, setLoading] = useState(false);
  const [anchor, setAnchor] = useState();
  const [cursor, setCursor] = useState("auto");
  const [viewport, setViewport] = useState({
    latitude: props.location?.center[0],
    longitude: props.location?.center[1],
    zoom: props.location?.zoom,
  });
  const handleRecenterClick = () => {
    setViewport({
      latitude: props.location?.center[0],
      longitude: props.location?.center[1],
      zoom: props.location?.zoom,
    });
  };

  const onMouseEnter = useCallback(() => setCursor("pointer"), []);
  const onMouseLeave = useCallback(() => setCursor("auto"), []);

  useEffect(() => {
    // when props.location changes, recenter on new location
    setViewport({
      latitude: props.location?.center[0],
      longitude: props.location?.center[1],
      zoom: props.location?.zoom,
    });
  }, [props.location]);

  const [activeLayers, setActiveLayers] = useState([
    "block-groups-outline",
    "charger-coverage",
    "sites",
  ]);
  const [popupInfo, setPopupInfo] = useState(undefined);
  const [markerType, setMarkerType] = useState("");
  const [displayMode, setDisplayMode] = useState("dark");
  const mapRef = useRef(null);

  const onClick = useCallback(async (event) => {
    const map = mapRef.current?.getMap();
    if (!map) {
      return;
    }

    const {
      features,
      point,
      lngLat: { lng, lat },
    } = event;
    let { x, y } = point;

    if (activeLayers.includes("charging-stations")) {
      try {
        const clusters = map.queryRenderedFeatures(event.point, {
          layers: ["clusters"],
        });

        if (clusters.length) {
          const clusterId = clusters[0].properties?.cluster_id;
          const source = map.getSource("charging-stations");

          source.getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return;

            const coordinates =
              clusters[0].geometry.type === "Point"
                ? clusters[0].geometry.coordinates
                : undefined;
            if (coordinates) {
              map.easeTo({ center: coordinates, zoom: zoom, animate: true });
            }
          });
          return;
        }
      } catch (e) {
        console.error(e);
      }
    }

    const chargingStation = features?.find(
      (charging) => charging?.layer.id === "charging-stations"
    );
    let hoveredFeature = chargingStation || features?.[0];
    const layer = hoveredFeature?.layer.id || "";
    setMarkerType(layer);

    if (layer === "sites") {
      const site = scoredSites.features.find(
        (feature) => feature.id === hoveredFeature?.id
      );
      if (site) {
        const updatedMap = map.easeTo({
          center: site.geometry.coordinates,
          zoom: 22,
        });
        const updatedPoint = updatedMap.project(site.geometry.coordinates);
        x = updatedPoint.x;
        y = updatedPoint.y;
        hoveredFeature = site;
      }
    }

    const position = y > 400 ? "bottom" : "top";
    setAnchor(position);

    const excludedLayers = [
      "block-groups-outline",
      "charger-coverage",
      "justice-40",
      "austin-energy",
      "bluebonnet-electric-cooperative",
      "school-districts",
    ];
    const isLayerExcluded = excludedLayers.includes(layer);

    setPopupInfo(
      hoveredFeature && {
        feature: hoveredFeature,
        x,
        y,
        lng: isLayerExcluded
          ? lng
          : hoveredFeature?.geometry?.coordinates[0] || lng,
        lat: isLayerExcluded
          ? lat
          : hoveredFeature?.geometry?.coordinates[1] || lat,
      }
    );
  }, []);

  const handleDisplayModeChange = () => {
    setLoading(true);
    setDisplayMode(displayMode === "light" ? "dark" : "light");
    setLoading(false);
  };

  useDebouncedEffect(
    () => {
      // don't attempt to load data if any dependencies are not yet defined,
      // as this side effect relies on other side effects loading data into
      // dependencies
      if (
        [props.selectedChargingDemandSimulation].some(
          (value) => value === undefined
        )
      ) {
        return;
      }
      async function loadData() {
        const apiToken = await getToken();
        setLoadingDemand(true);
        setLoadingAllDayDemand(true);
        try {
          const demand = await loadDemand(
            props.selectedChargingDemandSimulation.id,
            ["Public", "Office"],
            "all",
            props.location?.id,
            apiToken
          );
          const allDayDemand = await loadDemand(
            props.selectedChargingDemandSimulation.id,
            ["Home", "Office", "Public"],
            "all",
            props.location?.id,
            apiToken
          );
          const demandTypeRatios = await getDemandTypeRatios(
            props.selectedChargingDemandSimulation.id,
            "all",
            apiToken
          );
          setDemand(demand);
          setAllDayDemand(allDayDemand);
          setDemandTypeRatios(demandTypeRatios);
        } catch (e) {
          console.error(e);
        } finally {
          setLoadingDemand(false);
          setLoadingAllDayDemand(false);
          setLoadingDemandTypeRatios(false);
        }
        setLoadingDemand(false);
        setLoadingAllDayDemand(false);
        setLoadingDemandTypeRatios(false);
      }
      loadData();
    },
    [props.selectedChargingDemandSimulation, props.location],
    1000
  );

  useDebouncedEffect(
    () => {
      async function loadData() {
        setLoadingChargingCapacity(true);
        const apiToken = await getToken();
        const access = includePrivateChargers
          ? ["public", "private"]
          : ["public"];
        const evNetworksToUse = includeExclusiveNetworks
          ? evNetworks
          : evNetworks.filter(
              (evNetwork) => !EXCLUSIVE_EV_NETWORKS.includes(evNetwork)
            );
        let chargingCapacity = await loadChargingCapacity(
          props.selectedChargingDemandSimulation.id,
          selectedChargerLevels,
          evNetworksToUse,
          access,
          apiToken
        );
        setChargingCapacity(chargingCapacity);
        setLoadingChargingCapacity(false);
      }
      if (props.selectedChargingDemandSimulation) {
        loadData();
      }
    },
    [
      props.selectedChargingDemandSimulation,
      evNetworks,
      selectedChargerLevels,
      includePrivateChargers,
      includeExclusiveNetworks,
    ],
    1000
  );

  useEffect(() => {
    async function loadData() {
      const apiToken = await getToken();
      const siteCollections = await loadSiteCollections(apiToken);
      setSiteCollections(siteCollections);
      setSelectedSiteCollection(siteCollections[siteCollections.length - 1]);
      setRefreshData(false);
    }
    if (refreshData) {
      loadData();
    }
  }, [refreshData]);

  useEffect(() => {
    async function loadData() {
      setLoadingSites(true);
      const apiToken = await getToken();
      const access = includePrivateChargers
        ? ["public", "private"]
        : ["public"];
      const evNetworksToUse = includeExclusiveNetworks
        ? evNetworks
        : evNetworks.filter(
            (evNetwork) => !EXCLUSIVE_EV_NETWORKS.includes(evNetwork)
          );
      let sites = { type: "FeatureCollection", features: [] };
      try {
        sites = await loadSites(
          selectedSiteCollection,
          props.selectedChargingDemandSimulation.id,
          selectedChargerLevels,
          evNetworksToUse,
          access,
          apiToken
        );
      } catch (e) {
        // not error handling for now
      }
      setSitesFeatureCollection(sites);
      setLoadingSites(false);
    }
    if (selectedSiteCollection) {
      loadData();
    }
  }, [
    props.selectedChargingDemandSimulation,
    selectedSiteCollection,
    evNetworks,
    selectedChargerLevels,
    includePrivateChargers,
    includeExclusiveNetworks,
  ]);

  const handleSelectedChargerLevelsChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      let newSelectedPlugTypes = [...selectedChargerLevels];
      newSelectedPlugTypes.push(value);
      setSelectedChargerLevels(newSelectedPlugTypes);
    } else {
      let newSelectedPlugTypes = selectedChargerLevels.filter(
        (e) => e !== value
      );
      setSelectedChargerLevels(newSelectedPlugTypes);
    }
  };

  const handleSiteRankWeightsChange = (parameter, newWeight) => {
    setSelectedSites([]);
    const newSiteRankWeights = { ...siteRankWeights };
    newSiteRankWeights[parameter] = newWeight;
    setSiteRankWeights(newSiteRankWeights);
  };

  const handleSiteCollectionChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedSiteCollection(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const scoredSites = {
    type: "FeatureCollection",
    features: sitesFeatureCollection.features.map((site) => {
      const propertyScore = totalScore(site, siteRankWeights);
      site.properties.totalScore = propertyScore;
      site.id = site.properties.id;
      return site;
    }),
  };
  // sort sites by score and add rank property
  scoredSites.features.sort(
    (a, b) => b.properties.totalScore - a.properties.totalScore
  );
  scoredSites.features.forEach((site, index) => {
    if (
      index > 0 &&
      site.properties.totalScore ===
        scoredSites.features[index - 1].properties.totalScore
    ) {
      site.properties["rank"] =
        scoredSites.features[index - 1].properties["rank"];
    } else {
      site.properties["rank"] = index + 1;
    }
  });

  const tableColumns = [
    { field: "rank", headerName: "Rank", width: 70 },
    { field: "name", headerName: "Name", flex: 1 },
  ];

  const tableRows = scoredSites.features.map((site) => {
    return {
      id: site.properties.id,
      name: site.properties.name,
      rank: site.properties.rank,
    };
  });

  const scoreHelpText = (
    <span>
      The score provided for each site when this site list was uploaded.
    </span>
  );

  const coverageHelpText = (
    <>
      <span>
        The percentage of charging demand in the area that can be addressed
        through existing charging infrastructure (including public charging in
        area).
      </span>
      <br />
      <span>Lower charger coverage scores higher.</span>
    </>
  );

  const environmentalJusticeHelpText = (
    <>
      <span>
        Whether or not a site lies within a region designated as Clean Transit
        Disadvantaged according to the Federal Council of Environmental Quality
        Justice40 Clean Transit Pathway:
      </span>
      <br />
      <a href={"https://screeningtool.geoplatform.gov/en/methodology"}>
        Methodology & data - Climate & Economic Justice Screening Tool
        (geoplatform.gov)
      </a>
      <br />
      <span>Being disadvantaged scores higher.</span>
    </>
  );

  const { setIsOpen, setSteps, setCurrentStep } = useTour();
  const tourSteps = [
    {
      selector: "#map",
      content: (
        <>
          <Typography>
            The sites page displays a list of user-defined sites on the map and
            provides tools for evaluating them according to a variety of
            parameters.
          </Typography>
          <br />
          <Typography>
            <a
              href={
                "https://docs.google.com/document/d/14pLN3OYqK6KaL3Hi4geT8ghBQd85BDmA1HCFo3ptd-8/edit?usp=sharing"
              }
              target="_blank"
              rel="noreferrer"
            >
              Click here
            </a>{" "}
            for a detailed user guide.
          </Typography>
        </>
      ),
    },
    {
      selector: ".site-list-selector",
      content: (
        <Typography>
          The displayed list of sites can be selected here.
        </Typography>
      ),
    },
    {
      selector: ".new-site-list-button",
      content: (
        <Typography>
          A new list of sites can be uploaded by clicking this button.
        </Typography>
      ),
    },
    {
      selector: ".download-button",
      content: (
        <Typography>
          A CSV file of the currently displayed sites and their rankings can be
          downloaded by clicking this button.
        </Typography>
      ),
    },
    {
      selector: ".weight-controls",
      content: (
        <Typography>
          You can control how strongly various factors are considered in the
          total score calculation by adjusting the sliders.
        </Typography>
      ),
    },
    {
      selector: ".sites-legend",
      content: (
        <Typography>
          Sites are scored according to the provided weights and drawn on the
          map. Green properties have a higher score while red properties have a
          lower score. Higher scores are better candidates for charging
          infrastructure installation.
        </Typography>
      ),
    },
    {
      selector: ".sites-table",
      content: (
        <Typography>
          All sites in the current list are displayed in this table. You can
          click a site to go to it on the map. They are ranked in order, from
          highest score to lowest score.
        </Typography>
      ),
    },
    {
      selector: ".charger-controls",
      content: (
        <Typography>
          The coverage provided by chargers of different types and access levels
          can be controlled here. All chargers are assumed to have 10%
          utilization, i.e. they are being used 10% of the time.
        </Typography>
      ),
    },
    {
      selector: ".year-control",
      content: (
        <Typography>
          The projected EV charging demand for a given year can be selected
          here. Note that the existing EV infrastructure will always be for the
          infrastructure that exists today and is not configurable.
        </Typography>
      ),
    },
    {
      selector: ".layer-controls",
      content: (
        <Typography>
          The available map overlays can be toggled in this panel.
        </Typography>
      ),
    },
    {
      selector: ".mapboxgl-ctrl-geocoder",
      content: (
        <Typography>
          You can search for an address with this search button.
        </Typography>
      ),
    },
    {
      selector: ".recenter-button",
      content: (
        <Typography>
          Recenter the map at any time by clicking this button.
        </Typography>
      ),
    },
  ];

  const handleIncludePrivateChargersChange = (event) => {
    setIncludePrivateChargers(!includePrivateChargers);
  };

  const handleIncludeExclusiveNetworksChange = (event) => {
    setIncludeExclusiveNetworks(!includeExclusiveNetworks);
  };

  // Leaflet is hard to render and freezes the application. This is a separate state that is set on a delay so that
  // the entire application doesn't freeze when the year slider is being changed
  const [scaleYear, setScaleYear] = useState(props.year);
  useDebouncedEffect(
    () => {
      setScaleYear(props.year);
    },
    [props.year],
    1000
  );

  const [loadingGrowthScenario, setLoadingGrowthScenario] = useState(false);
  const [filteredGrowthScenarios, setFilteredGrowthScenarios] = useState([]);
  const [lightDutyVehicleClass, setLightDutyVehicleClass] = useState(undefined);
  useEffect(() => {
    setLoadingGrowthScenario(true);
    const filteredGs = props.growthScenarios?.filter((y) => y.isldv === true);
    setFilteredGrowthScenarios(filteredGs || []);
    if (filteredGs && filteredGs.length > 0) {
      const selectedGs =
        props.selectedGrowthScenario && props.selectedGrowthScenario.isldv
          ? props.selectedGrowthScenario.id
          : filteredGs[0].id;
      if (props.setSelectedGrowthScenarioId) {
        props.setSelectedGrowthScenarioId(selectedGs);
      }
    }
    setLightDutyVehicleClass(
      props.selectedGrowthScenario !== undefined
        ? getLightDutyVehicleClass(props.selectedGrowthScenario)
        : undefined
    );
    setLoadingGrowthScenario(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.growthScenarios, props.selectedGrowthScenario]);

  useEffect(() => {
    async function loadData() {
      setOverlays({});
      const apiToken = await getToken();
      let overlays = {};
      if (props.location?.name === "Austin") {
        const geoJsonOverlays = await loadOverlays(apiToken);
        geoJsonOverlays.forEach((geoJsonOverlay) => {
          switch (geoJsonOverlay?.properties?.name) {
            case "School Districts":
              overlays["school-districts"] = geoJsonOverlay;
              return;
            case "Austin Energy Service Territory":
              overlays["austin-energy"] = geoJsonOverlay;
              return;
            case "Bluebonnet Electric Cooperative Service Territory":
              overlays["bluebonnet-electric-cooperative"] = geoJsonOverlay;
              return;
            default:
          }
        });
      }
      let justice40 = { type: "FeatureCollection", features: [] };
      try {
        const state = getStateNameFromStateAbbr(props.location?.state || "");
        justice40 = await loadJustice40(state, apiToken);
      } catch (e) {
        // not error handling for now
      }
      justice40.properties = {};
      justice40.properties.name = "Justice 40";
      overlays["justice-40"] = justice40;
      setOverlays(overlays);
    }
    loadData();
  }, [props.location]);

  const scaledDemand = useMemo(() => {
    if (
      lightDutyVehicleClass?.annualData !== undefined &&
      scaleYear !== undefined
    ) {
      const allDayDemandSum = Number(
        Object.values(allDayDemand).reduce(
          (sum, val) => Number(sum) + Number(val),
          0
        )
      );
      return scaleDemandByGrowthScenarioYear(
        lightDutyVehicleClass.annualData,
        scaleYear,
        demand,
        allDayDemandSum,
        ["Public", "Office"],
        demandTypeRatios
      );
    }
  }, [
    lightDutyVehicleClass,
    scaleYear,
    demand,
    allDayDemand,
    demandTypeRatios,
  ]);
  const scaledChargingCapacity = useMemo(
    () => scaleChargingCapacityByUtilization(chargingCapacity, utilization),
    [chargingCapacity, utilization]
  );
  const coveragePercentage = useMemo(
    () => calculateCoveragePercentage(scaledDemand, scaledChargingCapacity),
    [scaledDemand, scaledChargingCapacity]
  );

  const showSitesOutsideRegionAlert = scoredSites?.features?.length === 0;

  function getBlockGroupPopupContent(
    blockGroup,
    scaledDemand,
    existingChargerCoverage,
    currentView
  ) {
    const geoid = blockGroup.properties.geoid;
    const area = blockGroup.properties.area;
    const demandkWh = scaledDemand
      ? scaledDemand[blockGroup.properties.geoid] ?? 0
      : 0; // if demand undefined, set 0
    const existingChargerCoveragekWh = existingChargerCoverage
      ? existingChargerCoverage[blockGroup.properties.geoid] ?? 0
      : 0; // if props.existingChargerCoverage undefined, set 0
    return (
      <BlockGroupPopupContent
        geoid={geoid}
        area={area}
        demand={demandkWh}
        existingChargerCoverage={existingChargerCoveragekWh}
        currentView={currentView}
      />
    );
  }

  const isTxPPC = ["TX", "CPS", "NBU", "LCRA", "AE"].includes(
    getStateAbbrFromStateName(props.location?.name || "")
  );

  function getPopupContent(popupInfo) {
    switch (markerType) {
      case "sites":
        return <SitePopupContent feature={popupInfo.feature} />;
      case "charger-coverage":
        return getBlockGroupPopupContent(
          popupInfo.feature,
          scaledDemand,
          0,
          "sites"
        );
      case "charging-stations":
        return <ChargingStationPopupContent feature={popupInfo.feature} />;
      case "poverty":
        return (
          <DemographicsPopupContent
            feature={popupInfo.feature}
            isTxPPC={isTxPPC}
            activeLayers={activeLayers}
          />
        );
      case "non-white":
        return (
          <DemographicsPopupContent
            feature={popupInfo.feature}
            isTxPPC={isTxPPC}
            activeLayers={activeLayers}
          />
        );
      case "mfh":
        return (
          <DemographicsPopupContent
            feature={popupInfo.feature}
            isTxPPC={isTxPPC}
            activeLayers={activeLayers}
          />
        );
      case "school-districts":
        return <SchoolDistrictPopupContent feature={popupInfo.feature} />;
      case "justice-40":
        return <Justice40PopupContent feature={popupInfo.feature} />;
      case "block-groups-outline":
        return (
          <>
            <Typography align="center">
              This is a block group boundary.
            </Typography>
            <Typography align="center">
              Click on a block group or marker to view more information.
            </Typography>
          </>
        );
      default:
        return (
          <GenericGeoJsonFeaturePopupContent feature={popupInfo.feature} />
        );
    }
  }

  const handleLayerChange = (layer) => () => {
    // if box is checked, layer is added to active-layers
    // if box is unchecked, layer is removed from active-layers
    if (activeLayers.includes(layer)) {
      setActiveLayers(activeLayers.filter((l) => l !== layer));
      if (markerType === layer) setPopupInfo(undefined);
    } else {
      setActiveLayers((prevState) => {
        return [...prevState, layer];
      });
      if (layer === "justice-40") {
        // remove and add back the sites layer
        setActiveLayers((prevState) => {
          return [...prevState.filter((l) => l !== "sites")];
        });
        setTimeout(() => {
          setActiveLayers((prevState) => {
            return [...prevState, "sites"];
          });
        }, 1);
      }
    }
  };

  function createSitesLegend() {
    return (
      <Widget sx={{ width: "150px" }}>
        <Stack
          className="sites-legend"
          spacing={2}
          divider={<Divider orientation="horizontal" flexItem />}
          sx={{ padding: "16px" }}
        >
          <Typography variant={"h3"}>{"Site Scores"}</Typography>
          <Box
            sx={{
              display: "grid",
              alignContent: "space-between",
              justifyContent: "center",
              gridTemplateColumns: "18px auto",
              gap: "10px",
              width: "100%",
              height: "120px",
            }}
          >
            <Box
              sx={{
                gridRow: "1 / 5",
                background:
                  "linear-gradient(0deg, rgba(255,0,0,0.8) 0%, rgba(188,101,0,0.8) 25%, rgba(142,117,0,0.8) 50%, rgba(96,124,0,0.8) 75%, rgba(0,128,0,0.8)  100%);",
              }}
            ></Box>
            <Typography
              sx={{
                position: "relative",
                top: "-5px",
                gridColumn: 2,
                gridRow: 1,
              }}
            >
              Higher Score
            </Typography>
            <Typography
              sx={{
                position: "relative",
                bottom: "-5px",
                gridColumn: 2,
                gridRow: 4,
              }}
            >
              Lower Score
            </Typography>
          </Box>
        </Stack>
      </Widget>
    );
  }

  function createSchoolDistrictLegend() {
    return (
      <Widget sx={{ width: "175px" }}>
        <Stack
          spacing={2}
          divider={<Divider orientation="horizontal" flexItem />}
          sx={{ padding: "16px" }}
        >
          <Typography variant={"h4"}>
            {"Student Eligible for Reduced Lunch"}
          </Typography>
          <Box
            sx={{
              display: "grid",
              alignContent: "space-between",
              justifyContent: "center",
              gridTemplateColumns: "18px auto",
              gap: "10px",
              width: "100%",
              height: "120px",
            }}
          >
            <Box
              sx={{
                gridRow: "1 / 6",
                background:
                  "linear-gradient(0deg, rgba(0,128,0,0.8) 0%, rgba(96,124,0,0.8) 25%, rgba(142,117,0,0.8) 50%, rgba(188,101,0,0.8) 75%, rgba(255,0,0,0.8) 100%);",
              }}
            ></Box>
            <Typography
              sx={{
                position: "relative",
                top: "-4px",
                gridColumn: 2,
                gridRow: 1,
              }}
            >
              100%
            </Typography>
            <Typography
              sx={{
                position: "relative",
                bottom: "0px",
                gridColumn: 2,
                gridRow: 5,
              }}
            >
              0%
            </Typography>
          </Box>
        </Stack>
      </Widget>
    );
  }

  const colors = [
    "#FFF2FE",
    0.25,
    "#FFA3F1",
    0.5,
    "#FD63DC",
    0.75,
    "#AD369D",
    1,
    "#5C1657",
  ];

  const coverageLegend = [
    "Less than 25",
    "#FFF2FE",
    "25 to 50",
    "#FFA3F1",
    "50 to 75",
    "#FD63DC",
    "75 to 100",
    "#AD369D",
    "Greater than 100",
    "#5C1657",
  ];

  const chargingStationsLegend = [
    "Public",
    "#05C2cc",
    "Private",
    "#aef359",
    "Exclusive",
    "#500c0c",
  ];

  const povertyLegend = [
    "25",
    "#0000ff",
    "50",
    "#0000ff",
    "75",
    "#0000ff",
    "100",
    "#0000ff",
  ];

  const nonWhiteLegend = [
    "25",
    "#ffeb7f",
    "50",
    "#ffeb7f",
    "75",
    "#ffeb7f",
    "100",
    "#ffeb7f",
  ];

  const mfhLegend = [
    "25",
    "#ff0000",
    "50",
    "#ff0000",
    "75",
    "#ff0000",
    "100",
    "#ff0000",
  ];

  const circleSizes = [3, 6, 9, 12];

  const coverageLayerStyle = {
    id: "charger-coverage",
    type: "fill",
    paint: {
      "fill-color": [
        "step",
        ["get", ["get", "geoid"], ["literal", coveragePercentage]],
        ...colors,
      ],
      "fill-opacity": 0.6,
    },
  };

  const blockGroupOutlineLayerStyle = {
    id: "block-groups-outline",
    type: "line",
    paint: {
      "line-color": "#000000",
      "line-width": 1,
    },
  };

  const chargingStationsLayerStyle = {
    id: "charging-stations",
    type: "circle",
    paint: {
      "circle-color": "#691c63",
      "circle-radius": 5,
      "circle-stroke-color": [
        "case",
        ["in", ["get", "evNetwork"], ["literal", EXCLUSIVE_EV_NETWORKS]],
        "#500c0c",
        ["==", ["get", "access"], "public"],
        "#05C2cc",
        ["==", ["get", "access"], "private"],
        "#aef359",
        "#c0c0c0",
      ],
      "circle-stroke-width": 2,
    },
  };

  const color = new Color("red");
  const greenRed = color.range("green", {
    space: "lch",
    outputSpace: "srgb",
  });

  const povertyLayerStyle = {
    id: "poverty",
    type: "circle",
    paint: {
      "circle-color": "#0000ff",
      "circle-radius": [
        "case",
        [
          "<",
          [
            "to-number",
            ["get", String(ACS_METRIC_POVERTY), ["get", "demographic"]],
          ],
          0.25,
        ],
        3,
        [
          "<",
          [
            "to-number",
            ["get", String(ACS_METRIC_POVERTY), ["get", "demographic"]],
          ],
          0.5,
        ],
        6,
        [
          "<",
          [
            "to-number",
            ["get", String(ACS_METRIC_POVERTY), ["get", "demographic"]],
          ],
          0.75,
        ],
        9,
        12,
      ],
      "circle-opacity": 0.8,
    },
  };

  const nonWhiteLayerStyle = {
    id: "non-white",
    type: "circle",
    paint: {
      "circle-color": "#ffeb7f",
      "circle-radius": [
        "case",
        [
          "<",
          [
            "to-number",
            ["get", String(ACS_METRIC_NONWHITE), ["get", "demographic"]],
          ],
          0.25,
        ],
        3,
        [
          "<",
          [
            "to-number",
            ["get", String(ACS_METRIC_NONWHITE), ["get", "demographic"]],
          ],
          0.5,
        ],
        6,
        [
          "<",
          [
            "to-number",
            ["get", String(ACS_METRIC_NONWHITE), ["get", "demographic"]],
          ],
          0.75,
        ],
        9,
        12,
      ],
      "circle-opacity": 0.8,
    },
  };

  const mfhLayerStyle = {
    id: "mfh",
    type: "circle",
    paint: {
      "circle-color": "#ff0000",
      "circle-radius": [
        "case",
        [
          "<",
          [
            "to-number",
            [
              "get",
              String(ACS_METRIC_MULTIFAMILY_HOUSING),
              ["get", "demographic"],
            ],
          ],
          0.25,
        ],
        3,
        [
          "<",
          [
            "to-number",
            [
              "get",
              String(ACS_METRIC_MULTIFAMILY_HOUSING),
              ["get", "demographic"],
            ],
          ],
          0.5,
        ],
        6,
        [
          "<",
          [
            "to-number",
            [
              "get",
              String(ACS_METRIC_MULTIFAMILY_HOUSING),
              ["get", "demographic"],
            ],
          ],
          0.75,
        ],
        9,
        12,
      ],
      "circle-opacity": 0.8,
    },
  };

  const justice40FillLayerStyle = {
    id: "justice-40",
    type: "fill",
    paint: {
      "fill-color": "blue",
      "fill-opacity": 0.6,
      "fill-outline-color": "blue",
    },
  };

  const justice40LineLayerStyle = {
    id: "justice-line-40",
    type: "line",
    paint: {
      "line-color": "blue",
      "line-opacity": 0.8,
    },
  };

  const schoolDistrictColor = new Color("green").range("red", {
    space: "lch",
    outputSpace: "srgb",
  });

  const schoolDistrictLayerStyle = {
    id: "school-districts",
    type: "fill",
    paint: {
      "fill-color": [
        "step",
        ["get", "reduced_eligible_fraction"],
        schoolDistrictColor(0).toString({ format: "hex" }),
        0.1,
        schoolDistrictColor(0.1).toString({ format: "hex" }),
        0.25,
        schoolDistrictColor(0.25).toString({ format: "hex" }),
        0.4,
        schoolDistrictColor(0.4).toString({ format: "hex" }),
        0.5,
        schoolDistrictColor(0.5).toString({ format: "hex" }),
        0.6,
        schoolDistrictColor(0.6).toString({ format: "hex" }),
        0.75,
        schoolDistrictColor(0.75).toString({ format: "hex" }),
        0.9,
        schoolDistrictColor(0.9).toString({ format: "hex" }),
        1,
        schoolDistrictColor(1).toString({ format: "hex" }),
      ],
      "fill-opacity": 0.2,
    },
  };

  const schoolDistrictsLineLayerStyle = {
    id: "school-districts-line",
    type: "line",
    paint: {
      "line-opacity": 1,
      "line-width": 3,
      "line-color": [
        "step",
        ["get", "reduced_eligible_fraction"],
        schoolDistrictColor(0).toString({ format: "hex" }),
        0.1,
        schoolDistrictColor(0.1).toString({ format: "hex" }),
        0.25,
        schoolDistrictColor(0.25).toString({ format: "hex" }),
        0.4,
        schoolDistrictColor(0.4).toString({ format: "hex" }),
        0.5,
        schoolDistrictColor(0.5).toString({ format: "hex" }),
        0.6,
        schoolDistrictColor(0.6).toString({ format: "hex" }),
        0.75,
        schoolDistrictColor(0.75).toString({ format: "hex" }),
        0.9,
        schoolDistrictColor(0.9).toString({ format: "hex" }),
        1,
        schoolDistrictColor(1).toString({ format: "hex" }),
      ],
    },
  };

  const blueBonnetElectricLayerStyle = {
    id: "bluebonnet-electric-cooperative",
    type: "fill",
    paint: {
      "fill-color": "#1267B2",
      "fill-opacity": 0.2,
    },
  };

  const blueBonnetElectricLineLayerStyle = {
    id: "bluebonnet-electric-cooperative-line",
    type: "line",
    paint: {
      "line-color": "#1267B2",
      "line-opacity": 1,
      "line-width": 3,
    },
  };

  const austinEnergyLayerStyle = {
    id: "austin-energy",
    type: "fill",
    paint: {
      "fill-color": "#F05F2B",
      "fill-opacity": 0.3,
    },
  };

  const austinEnergyLineLayerStyle = {
    id: "austin-energy-line",
    type: "line",
    paint: {
      "line-color": "#F05F2B",
      "line-opacity": 1,
      "line-width": 3,
    },
  };

  const demographicsGeoJson = useMemo(() => {
    if (props.demographics !== undefined) {
      let features = [];
      for (const [geoid, demographic] of Object.entries(props.demographics)) {
        const blockGroup = props.blockGroups?.features.find(
          (feature) => feature.properties?.geoid === geoid
        );
        if (blockGroup) {
          const centroid = blockGroup.properties?.centroid;
          const feature = {
            type: "Feature",
            properties: {
              geoid,
              demographic,
            },
            geometry: {
              type: "Point",
              coordinates: centroid.coordinates,
            },
          };
          features.push(feature);
        }
      }
      return {
        type: "FeatureCollection",
        features,
      };
    }
    return undefined;
  }, [props.demographics, props.blockGroups]);

  // Calculate score and find extreme scores
  let maxScore = -Infinity;
  let minScore = Infinity;

  scoredSites.features.forEach((feature) => {
    const propertyScore = feature.properties.totalScore;
    if (propertyScore > maxScore) {
      maxScore = propertyScore;
    }
    if (propertyScore < minScore) {
      minScore = propertyScore;
    }
  });

  const sitesLayerStyle = {
    id: "sites",
    type: "circle",
    paint: {
      "circle-radius": 10,
      "circle-opacity": 0.8,
      "circle-color": [
        "step",
        ["get", "totalScore"],
        greenRed(0).toString({ format: "hex" }),
        minScore,
        greenRed(0.1).toString({ format: "hex" }),
        minScore + (maxScore - minScore) * 0.25,
        greenRed(0.25).toString({ format: "hex" }),
        minScore + (maxScore - minScore) * 0.4,
        greenRed(0.4).toString({ format: "hex" }),
        minScore + (maxScore - minScore) * 0.5,
        greenRed(0.5).toString({ format: "hex" }),
        minScore + (maxScore - minScore) * 0.6,
        greenRed(0.6).toString({ format: "hex" }),
        minScore + (maxScore - minScore) * 0.75,
        greenRed(0.75).toString({ format: "hex" }),
        minScore + (maxScore - minScore) * 0.9,
        greenRed(0.9).toString({ format: "hex" }),
        maxScore,
        greenRed(1).toString({ format: "hex" }),
      ],
    },
  };

  const sitesLoading =
    loading ||
    props.loading ||
    loadingSites ||
    loadingChargingCapacity ||
    loadingDemand ||
    loadingAllDayDemand ||
    loadingDemandTypeRatios ||
    loadingGrowthScenario ||
    scaleYear !== props.year;

  return (
    <Stack direction={"row"} sx={{ height: "100%" }}>
      <Stack
        sx={{
          width: "462px",
          height: "100%",
          padding: "30px",
          overflowY: "auto",
          boxSizing: "border-box",
        }}
        divider={<Divider />}
      >
        <Box sx={{ paddingBottom: "1em" }}>
          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            <Typography variant="controlTitle">Sites</Typography>
            <Button
              onClick={() => {
                setSteps(tourSteps);
                setCurrentStep(0);
                setIsOpen(true);
              }}
              color="info"
              startIcon={<HelpOutlineIcon />}
              variant={"outlined"}
              size={"small"}
            >
              Tutorial
            </Button>
          </Stack>
          <AccessControl permission={"read:sites"}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={"flex-start"}
              spacing={1}
            >
              <InfoIcon color="info" fontSize="small" />
              <Typography>Click a site to see detailed information</Typography>
            </Stack>
          </AccessControl>
        </Box>
        <Stack spacing={2} alignItems paddingTop={2} divider={<Divider />}>
          <Stack spacing={2}>
            <GrowthScenarioControl
              selectedGrowthScenario={
                filteredGrowthScenarios?.find(
                  (scenario) => scenario.id === props.selectedGrowthScenario?.id
                ) || undefined
              }
              growthScenarios={filteredGrowthScenarios}
              onChange={props.onGrowthScenarioChange}
              disabled={props.loading || loadingGrowthScenario}
            />
            <Stack direction={"row"} alignItems={"center"}>
              <FormControl
                sx={{ flexGrow: "1" }}
                className="site-list-selector"
              >
                <InputLabel>Site Lists</InputLabel>
                <Select
                  label={"Site Lists"}
                  value={selectedSiteCollection || ""}
                  onChange={handleSiteCollectionChange}
                >
                  {siteCollections.map((siteCollection) => {
                    return (
                      <MenuItem key={siteCollection.id} value={siteCollection}>
                        <ListItemText primary={siteCollection.name} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <Box>
                <Tooltip title="Add a new site list">
                  <IconButton
                    onClick={() => setAddSiteCollectionModalOpen(true)}
                    size="large"
                    className="new-site-list-button"
                  >
                    <AddBoxIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Download the currently displayed sites data">
                  <IconButton
                    onClick={() =>
                      download(
                        createSafeFilename(
                          `ev-insites_report_${
                            selectedSiteCollection.name
                          }_${new Date().toISOString()}.csv`
                        ),
                        scoredSites,
                        siteRankWeights
                      )
                    }
                    className={"download-button"}
                  >
                    <DownloadIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Stack>
            {showSitesOutsideRegionAlert && (
              <Alert severity="warning">
                No sites from this list are part of the currently displayed
                region.
              </Alert>
            )}
            <YearControl
              value={props.year}
              years={range(2020, 2051)}
              onChange={props.onYearChange}
            />
          </Stack>
          <Stack spacing={1} className="weight-controls">
            <Typography variant="h3">Controls</Typography>
            <Stack spacing={1} direction={"row"}>
              <IconButton
                aria-label="charger-coverage-control-icon"
                onClick={() =>
                  setChargerCoverageControlsOpen(!chargerCoverageControlsOpen)
                }
              >
                <EvStationIcon fontSize="large" htmlColor="#FDBE02" />
              </IconButton>
              <Link
                component="button"
                variant="inherit"
                sx={{ fontWeight: 500 }}
                onClick={() =>
                  setChargerCoverageControlsOpen(!chargerCoverageControlsOpen)
                }
                className={"charger-coverage-controls-link"}
                align="left"
              >
                Manage Charger Coverage Controls
              </Link>
            </Stack>
            <Stack spacing={1} direction={"row"}>
              <IconButton
                aria-label="layer-controls-icon"
                onClick={() => setLayerControlsOpen(!layerControlsOpen)}
              >
                <LayersIcon fontSize="large" htmlColor="#FDBE02" />
              </IconButton>
              <Link
                component="button"
                variant="inherit"
                sx={{ fontWeight: 500 }}
                onClick={() => setLayerControlsOpen(!layerControlsOpen)}
                className={"layer-controls-link"}
                align="left"
              >
                Manage Layer Controls
              </Link>
            </Stack>
          </Stack>
          <Stack spacing={1} className="weight-controls">
            <Typography variant="h3">Ranking Weights</Typography>
            <FormGroup>
              <FormControl>
                <HelpTooltip title={scoreHelpText}>
                  <FormLabel>User Score</FormLabel>
                </HelpTooltip>
                <Box className="user-score-weight-control">
                  <InputSlider
                    value={siteRankWeights.score}
                    min={0}
                    max={10}
                    onChange={(value) =>
                      handleSiteRankWeightsChange("score", value)
                    }
                  />
                </Box>
              </FormControl>
              <FormControl>
                <HelpTooltip title={environmentalJusticeHelpText}>
                  <FormLabel>Disadvantaged Community</FormLabel>
                </HelpTooltip>
                <Box className="env-justice-weight-control">
                  <InputSlider
                    value={siteRankWeights.justice40}
                    min={0}
                    max={10}
                    onChange={(value) =>
                      handleSiteRankWeightsChange("justice40", value)
                    }
                  />
                </Box>
              </FormControl>
              <FormControl>
                <HelpTooltip title={coverageHelpText}>
                  <FormLabel>Charger Coverage</FormLabel>
                </HelpTooltip>
                <Box className="charger-coverage-weight-control">
                  <InputSlider
                    value={siteRankWeights.chargerCoverage}
                    min={0}
                    max={10}
                    onChange={(value) =>
                      handleSiteRankWeightsChange("chargerCoverage", value)
                    }
                  />
                </Box>
              </FormControl>
            </FormGroup>
          </Stack>
          <Stack spacing={1} flexGrow={1} className="sites-table">
            <Typography variant="h3">Site Rankings</Typography>
            <Box sx={{ minHeight: 370, height: "100%", width: "100%" }}>
              <DataGrid
                rows={tableRows}
                columns={tableColumns}
                hideFooterSelectedRowCount={true}
                rowsPerPageOptions={[100]}
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectedSites(newSelectionModel);
                  if (newSelectionModel) {
                    const site = scoredSites.features.find(
                      (feature) => feature.id === newSelectionModel[0]
                    );
                    const map = mapRef.current?.getMap();
                    if (site && map) {
                      const updatedMap = map.easeTo({
                        center: site.geometry.coordinates,
                        zoom: 22,
                      });
                      const { x, y } = updatedMap.project(
                        site.geometry.coordinates
                      );
                      let position = "top";
                      if (y > 400) {
                        position = "bottom";
                      }
                      setAnchor(position);
                      setPopupInfo({
                        feature: site,
                        x,
                        y,
                        lng: site.geometry.coordinates[0],
                        lat: site.geometry.coordinates[1],
                      });
                      setMarkerType("sites");
                    }
                  }
                }}
                selectionModel={selectedSites}
              />
            </Box>
          </Stack>
        </Stack>
      </Stack>
      <UploadSiteCollectionModal
        open={addSiteCollectionModalOpen}
        onClose={() => setAddSiteCollectionModalOpen(false)}
        refreshSiteCollections={() => setRefreshData(true)}
      />

      <Box sx={{ height: "100%", flex: 1, position: "relative" }}>
        <Backdrop
          sx={{
            color: "#FFFFFF",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            position: "absolute",
          }}
          open={sitesLoading}
        >
          <Stack alignItems={"center"} spacing={2}>
            <CircularProgress color="inherit" />
            <Collapse
              in={
                loadingDemand || loadingAllDayDemand || loadingDemandTypeRatios
              }
            >
              <Typography variant="h3">Loading sites...</Typography>
            </Collapse>
            <Collapse in={props.loading}>
              <Typography variant="h3">Loading data...</Typography>
            </Collapse>
          </Stack>
        </Backdrop>
        <Stack
          spacing={2}
          sx={{
            position: "absolute",
            margin: "8px",
            zIndex: "1000",
            top: 0,
            right: 0,
            height: `calc(100% - 20px - 8px * 2)`,
            pointerEvents: "none",
          }}
          alignItems={"flex-end"}
          direction={"column"}
        >
          <Box
            sx={{
              borderRadius: "10px",
              backgroundColor:
                displayMode === "dark"
                  ? "hsla(0,0%,100%,.8)"
                  : "hsla(0,0%,0%,.8)",
              pointerEvents: "auto",
              paddingRight: "10px",
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={displayMode === "dark"}
                  onChange={handleDisplayModeChange}
                />
              }
              label="Dark Mode"
              labelPlacement="start"
              componentsProps={{
                typography: {
                  color: displayMode === "dark" ? "black" : "white",
                },
              }}
            />
          </Box>
        </Stack>
        <WidgetContainer>
          <Widget sx={{ width: "270px" }}>
            <Legend
              title={"Existing Charger Coverage"}
              colors={coverageLegend}
              units={"%"}
            />
          </Widget>
          {activeLayers.includes("sites") && createSitesLegend()}
          {activeLayers.includes("school-districts") &&
            createSchoolDistrictLegend()}
          {activeLayers.includes("charging-stations") && (
            <Widget sx={{ width: "210px" }}>
              <Legend
                title={"Charging Stations"}
                colors={chargingStationsLegend}
                units={""}
              />
            </Widget>
          )}
          {activeLayers.includes("poverty") && (
            <Widget sx={{ width: "210px" }}>
              <DemographicsLegend
                title={"Poverty"}
                colors={povertyLegend}
                sizes={circleSizes}
              />
            </Widget>
          )}
          {activeLayers.includes("non-white") && (
            <Widget sx={{ width: "210px" }}>
              <DemographicsLegend
                title={"Non-White"}
                colors={nonWhiteLegend}
                sizes={circleSizes}
              />
            </Widget>
          )}
          {activeLayers.includes("mfh") && (
            <Widget sx={{ width: "210px" }}>
              <DemographicsLegend
                title={"Multifamily Housing"}
                colors={mfhLegend}
                sizes={circleSizes}
              />
            </Widget>
          )}
        </WidgetContainer>
        <Map
          id="map"
          {...viewport}
          ref={mapRef}
          onMove={(evt) => setViewport(evt.viewState)}
          mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          cursor={cursor}
          mapStyle={
            displayMode === "dark"
              ? "mapbox://styles/mapbox/dark-v11"
              : "mapbox://styles/mapbox/light-v11"
          }
          interactiveLayerIds={[
            coverageLayerStyle.id || "",
            blockGroupOutlineLayerStyle.id || "",
            sitesLayerStyle.id || "",
            chargingStationsLayerStyle.id || "",
            povertyLayerStyle.id || "",
            nonWhiteLayerStyle.id || "",
            mfhLayerStyle.id || "",
            justice40FillLayerStyle.id || "",
            austinEnergyLayerStyle.id || "",
            blueBonnetElectricLayerStyle.id || "",
            schoolDistrictLayerStyle.id || "",
          ]}
        >
          <GeocoderControl
            mapboxAccessToken={MAPBOX_ACCESS_TOKEN ?? ""}
            position="top-left"
          />
          <NavigationControl position={"top-left"} />
          <HomeButton
            // when the location changes, we need to render a new HomeButton
            // because otherwise the listeners do not get updated with the new
            // value of location. The HomeButton's onRemove() will remove the
            // previous element from the map
            key={props.location?.name}
            onClick={handleRecenterClick}
          />
          {activeLayers.includes("charger-coverage") && (
            <Source
              id="charger-coverage"
              type="geojson"
              data={props.blockGroups}
            >
              <Layer beforeId="waterway-label" {...coverageLayerStyle} />
            </Source>
          )}
          {activeLayers.includes("block-groups-outline") && (
            <Source
              id="block-groups-outline"
              type="geojson"
              data={props.blockGroups}
            >
              <Layer
                beforeId="waterway-label"
                {...blockGroupOutlineLayerStyle}
              />
            </Source>
          )}
          {activeLayers.includes("sites") && (
            <Source id="sites" type="geojson" data={scoredSites}>
              <Layer beforeId="waterway-label" {...sitesLayerStyle} />
            </Source>
          )}
          {activeLayers.includes("charging-stations") && (
            <Source
              id="charging-stations"
              type="geojson"
              data={props.chargingStations}
              cluster={true}
              clusterMinPoints={5}
              clusterMaxZoom={16} // Max zoom to cluster points on
              clusterRadius={50} // Radius of each cluster when clustering points (defaults to 50)
            >
              <Layer
                id="clusters"
                type="circle"
                source="charging-stations"
                filter={["has", "point_count"]}
                paint={{
                  "circle-color": "#691c63",
                  "circle-radius": 18,
                  "circle-stroke-width": 5,
                  "circle-stroke-color": "#691c63",
                  "circle-stroke-opacity": 0.3,
                }}
              />
              <Layer
                id="cluster-count"
                type="symbol"
                source="charging-stations"
                filter={["has", "point_count"]}
                layout={{
                  "text-field": "{point_count}",
                  "text-size": 10,
                }}
                paint={{
                  "text-color": "#ffffff",
                }}
              />
              <Layer
                beforeId="waterway-label"
                {...chargingStationsLayerStyle}
                filter={["!", ["has", "point_count"]]}
              />
            </Source>
          )}
          {activeLayers.includes("poverty") && (
            <Source id="poverty" type="geojson" data={demographicsGeoJson}>
              <Layer beforeId="waterway-label" {...povertyLayerStyle} />
            </Source>
          )}
          {activeLayers.includes("non-white") && (
            <Source id="non-white" type="geojson" data={demographicsGeoJson}>
              <Layer beforeId="waterway-label" {...nonWhiteLayerStyle} />
            </Source>
          )}
          {activeLayers.includes("mfh") && (
            <Source id="mfh" type="geojson" data={demographicsGeoJson}>
              <Layer beforeId="waterway-label" {...mfhLayerStyle} />
            </Source>
          )}
          {activeLayers.includes("justice-40") && overlays["justice-40"] && (
            <Source
              id="justice-40"
              type="geojson"
              data={overlays["justice-40"]}
            >
              <Layer beforeId="waterway-label" {...justice40FillLayerStyle} />
              <Layer beforeId="waterway-label" {...justice40LineLayerStyle} />
            </Source>
          )}
          {activeLayers.includes("austin-energy") &&
            overlays["austin-energy"] && (
              <Source
                id="austin-energy"
                type="geojson"
                data={overlays["austin-energy"]}
              >
                <Layer beforeId="waterway-label" {...austinEnergyLayerStyle} />
                <Layer
                  beforeId="waterway-label"
                  {...austinEnergyLineLayerStyle}
                />
              </Source>
            )}
          {activeLayers.includes("school-districts") &&
            overlays["school-districts"] && (
              <Source
                id="school-districts"
                type="geojson"
                data={overlays["school-districts"]}
              >
                <Layer
                  beforeId="waterway-label"
                  {...schoolDistrictLayerStyle}
                />
                <Layer
                  beforeId="waterway-label"
                  {...schoolDistrictsLineLayerStyle}
                />
              </Source>
            )}
          {activeLayers.includes("bluebonnet-electric-cooperative") &&
            overlays["bluebonnet-electric-cooperative"] && (
              <Source
                id="bluebonnet-electric-cooperative"
                type="geojson"
                data={overlays["bluebonnet-electric-cooperative"]}
              >
                <Layer
                  beforeId="waterway-label"
                  {...blueBonnetElectricLayerStyle}
                />
                <Layer
                  beforeId="waterway-label"
                  {...blueBonnetElectricLineLayerStyle}
                />
              </Source>
            )}

          {popupInfo && (
            <Popup
              style={{ zIndex: 1100 }}
              anchor={anchor}
              longitude={popupInfo.lng}
              latitude={popupInfo.lat}
              onClose={() => setPopupInfo(undefined)}
              maxWidth={"none"}
              closeButton={false}
            >
              {getPopupContent(popupInfo)}
            </Popup>
          )}
        </Map>
      </Box>

      {/* Charger Controls Dialog */}
      {chargerCoverageControlsOpen && (
        <ChargerCoverageControlsDialog
          open={chargerCoverageControlsOpen}
          onClose={() => setChargerCoverageControlsOpen(false)}
          props={props}
          selectedChargerLevels={selectedChargerLevels}
          handleSelectedChargerLevelsChange={handleSelectedChargerLevelsChange}
          includePrivateChargers={includePrivateChargers}
          handleIncludePrivateChargersChange={
            handleIncludePrivateChargersChange
          }
          includeExclusiveNetworks={includeExclusiveNetworks}
          handleIncludeExclusiveNetworksChange={
            handleIncludeExclusiveNetworksChange
          }
        />
      )}

      {/* Layer Controls Dialog */}
      {layerControlsOpen && (
        <LayerControlsDialog
          open={layerControlsOpen}
          onClose={() => setLayerControlsOpen(false)}
          props={props}
          activeLayers={activeLayers}
          handleLayerChange={handleLayerChange}
        />
      )}
    </Stack>
  );
}

export default SitesPage;
