import { ArrowDownwardSharp } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { FleetScenario } from "types/fleets";

export type ConfigurationParametersProps = {
  scenario?: FleetScenario;
  isDownloading?: boolean;
};

export default function ConfigurationParameters({
  scenario,
  isDownloading = false,
}: ConfigurationParametersProps) {
  return (
    <Stack spacing={2}>
      <Accordion defaultExpanded={isDownloading}>
        <AccordionSummary expandIcon={<ArrowDownwardSharp />}>
          <Typography variant="controlTitle" sx={{ fontWeight: "bold" }}>
            Configuration Parameters
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2} direction={"row"}>
            <List sx={{ width: "50%" }}>
              <ListItem disableGutters>
                <ListItemText
                  primary="Application"
                  secondary={scenario?.application.toString()}
                />
              </ListItem>
              <Divider component="li" />
              <ListItem disableGutters>
                <ListItemText
                  primary="Maximum No of Chargers"
                  secondary={scenario?.max_no_of_chargers}
                />
              </ListItem>
              <Divider component="li" />
              <ListItem disableGutters>
                <ListItemText
                  primary="Cost of ICE/Diesel Fuel"
                  secondary={`$${scenario?.fuel_cost} per gallon`}
                />
              </ListItem>
              <Divider component="li" />
              <ListItem disableGutters>
                <ListItemText
                  primary="Maintenance Downtime % for Diesel Vehicles"
                  secondary={scenario?.annual_ice_maintenance}
                />
              </ListItem>
              <Divider component="li" />
              <ListItem disableGutters>
                <ListItemText
                  primary="Maintenance Downtime % for Electric Vehicles"
                  secondary={scenario?.annual_ev_maintenance}
                />
              </ListItem>
              <Divider component="li" />
              <ListItem disableGutters>
                <ListItemText
                  primary="Average EV Energe Charge"
                  secondary={`${scenario?.avg_ev_energy_charge} ($/kWh)`}
                />
              </ListItem>
              <Divider component="li" />
              <ListItem disableGutters>
                <ListItemText
                  primary="Average EV Demand Charge"
                  secondary={`${scenario?.avg_ev_demand_charge} $/kW`}
                />
              </ListItem>
            </List>
            <List sx={{ width: "50%" }}>
              <ListItem disableGutters>
                <ListItemText
                  primary="Planning Horizon"
                  secondary={`${scenario?.planning_horizon} years`}
                />
              </ListItem>
              <Divider component="li" />
              <ListItem disableGutters>
                <ListItemText
                  primary="Where is your fleet going to charge?"
                  secondary={scenario?.charge_location.join(", ")}
                />
              </ListItem>
              <Divider component="li" />
              <ListItem disableGutters>
                <ListItemText
                  primary="Is your fleet owned or leased?"
                  secondary={scenario?.ownership_status}
                />
              </ListItem>
              <Divider component="li" />
              <ListItem disableGutters>
                <ListItemText
                  primary="Do you have multiple depot locations?"
                  secondary={scenario?.multiple_locations ? "Yes" : "No"}
                />
              </ListItem>
              <Divider component="li" />
              <ListItem disableGutters>
                <ListItemText
                  primary="What best describes your common daily routes?"
                  secondary={scenario?.depot_route}
                />
              </ListItem>
              <Divider component="li" />
              <ListItem disableGutters>
                <ListItemText
                  primary="What statement best describes your company?"
                  secondary={scenario?.attitude}
                />
              </ListItem>
              <Divider component="li" />
              <ListItem disableGutters>
                <ListItemText
                  primary="Scope"
                  secondary={scenario?.scope.join(", ")}
                />
              </ListItem>
            </List>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
}
