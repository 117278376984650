import { ArrowDownwardSharp } from "@mui/icons-material";
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

export type AssumptionsPropertyTableProps = {
  isDownloading?: boolean;
};

export default function AssumptionsPropertyTable({
  isDownloading = false,
}: AssumptionsPropertyTableProps) {
  // Temporarily removed LifeCycle and Energy Consumption lines as we didn't get it from the user.
  const equipmentLifecycleStr = "12 years";
  // scenario !== undefined ? `${scenario?.equipmentLifecycleYears} years` : "";

  return (
    <Stack spacing={2}>
      <Accordion defaultExpanded={isDownloading}>
        <AccordionSummary expandIcon={<ArrowDownwardSharp />}>
          <Typography variant="controlTitle" sx={{ fontWeight: "bold" }}>
            Assumptions
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            <ListItem disableGutters>
              <ListItemText
                primary="Equipment Lifecycle"
                secondary={equipmentLifecycleStr}
              />
            </ListItem>
            <Divider component="li" />
            <ListItem disableGutters>
              <ListItemText
                primary="Workdays"
                secondary={"365 workdays per year"}
              />
            </ListItem>
            <Divider component="li" />
            <ListItem disableGutters>
              <ListItemText
                primary="Driver Salary"
                secondary={"$80,000 per driver per year"}
              />
            </ListItem>
            <Divider component="li" />
            <ListItem disableGutters>
              <ListItemText
                primary="Vehicle Insurance"
                secondary={"3% of purchase cost per vehicle per year"}
              />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
}
